import {memo, useEffect, useState} from 'react';
// Local Import
import {addDays, checkDateBetween, formatDate} from "../../utils/DateUtils";
import usePrevious from "../../hooks/usePrevious";
// MUI
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select, TextField,
    Typography
} from "@mui/material";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
// COMPONENTS
import {AlertDialog} from "../../components";
// STORE
import {useAppDispatch, useAppSelector} from "../../store/store";
import {getCurrentVersion, getVersionDates} from "../../store/version/versionSelector";
import {getValuationFunds} from "../../store/valuationModel/selectors/generalSelectors";
import {selectFund} from "../../store/valuationModel/valuationModelSlice";
import {changeVersionsDate, createVersion, publishVersion, saveVersion} from "../../store/version/versionFunctions";
import {changeVersion} from "../../store/version/versionSlice";
import {SaveStatus} from "../../types/valuationModelEnums";

function VersionConfigBar() {
    const dispatch = useAppDispatch();
    const versionDates = useAppSelector(state => getVersionDates(state));
    const version = useAppSelector(state => getCurrentVersion(state));
    const versionsOnDate = useAppSelector(state => state.version.versionsOnDate);
    const funds = useAppSelector(state => getValuationFunds(state));
    const selectedFund = useAppSelector(state => state.valuationModel.fund);
    const versionSaving = useAppSelector(state => state.version.saving);
    const prevSaving = usePrevious(versionSaving);
    const actions = useAppSelector(state => state.user.user?.permissions.actions.map(a => a.permission));

    const [selectDate, setDate] = useState<Date | null>(null)
    const [dateChange, setDateChange] = useState<boolean>(false)
    const [selectVersion, setVersion] = useState<string | number | null>(null);
    const [save, setSave] = useState<boolean>(false);
    const [saveAs, setSaveAs] = useState<boolean>(false);
    const [publish, setPublish] = useState<boolean>(false);
    const [versionName, setVersionName] = useState<string>('');

    useEffect(() => {
        if (version) {
            setVersionName(version.name)
        }
    }, [version])

    // Close component when finished saving
    useEffect(() => {
        if (!!prevSaving && !versionSaving) {
            setSave(false)
            setSaveAs(false)
        }
    }, [versionSaving, prevSaving])

    // Set Select Date
    useEffect(() => {
        if ((!selectDate && version)) {
            setDate(new Date(version.valuationDate))
        }
    }, [selectDate, version, setDate])

    const checkDisable = (day: Date | string | number) => {
        const yesterday = addDays(new Date(), -1);
        const lastPub = versionDates[versionDates.length - 1];

        // Check for weekend
        if (new Date(day).getDay() === 0 || new Date(day).getDay() === 6) return true;

        if (checkDateBetween(day, lastPub, yesterday)) return false
        return !versionDates.includes(formatDate(new Date(day)));
    }

    return (
        <>
            <Box sx={{flex: 1, pl: 2}}>
                <Grid container direction='row' justifyContent='flex-end' alignItems='center' spacing={1}>
                    <Grid item md={2}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Select Fund</InputLabel>
                            <Select
                                size='small'
                                label='Select Fund'
                                value={selectedFund}
                                onChange={(event) => dispatch(selectFund(event.target.value))}
                            >
                                <MenuItem value={'ALL_VM_FUNDS'}>All Valuation Funds</MenuItem>
                                {funds.map(fund => (
                                    <MenuItem value={fund} key={fund}>{fund}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            {selectDate ?
                                <DesktopDatePicker
                                    value={selectDate}
                                    onChange={(event) => {
                                        setDate(event);
                                        setDateChange(true);
                                    }}
                                    format="dd/MM/yyyy"
                                    shouldDisableDate={checkDisable}
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            fullWidth: true
                                        },
                                        day: {
                                            sx: {
                                                '&.Mui-disabled': {
                                                    bgcolor: 'white'
                                                },
                                                '&.Mui-selected': {
                                                    bgcolor: 'primary.main',
                                                    opacity: 1
                                                },
                                                '&.MuiButtonBase-root-MuiPickersDay-root': {
                                                    opacity: 1
                                                },
                                                '&.MuiPickersDay-today': {
                                                    border: 'none'
                                                },
                                                bgcolor: 'grey.200',
                                                opacity: '1 !important',
                                                fontWeight: 'bold',
                                                color: 'primary.main',
                                                borderRadius: 1,
                                                '&:hover': {
                                                    bgcolor: 'primary.light',
                                                    color: 'white'
                                                }
                                            }
                                        }
                                    }}
                                />
                                :
                                <>
                                    <CircularProgress/>
                                </>
                            }
                        </LocalizationProvider>
                    </Grid>
                    <Grid item md={2}>
                        {version &&
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>Select Version</InputLabel>
                                <Select
                                    size='small'
                                    label='Select Version'
                                    value={version.id}
                                    onChange={(event) => setVersion(event.target.value)}
                                >
                                    {versionsOnDate.map(version => (
                                        <MenuItem key={version.id}
                                                  value={version.id}>{version.name} ({version.published ? 'Published' : 'Drafted'})</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        }
                    </Grid>
                    {actions?.some(a => a === 'vm_edit_all') &&
                        <Grid item md={1.5}>
                            <Button
                                variant='contained'
                                fullWidth
                                onClick={() => setSaveAs(true)}
                            >
                                Save As
                            </Button>
                        </Grid>
                    }
                    {version && (version.status !== SaveStatus.NEW) && (!version.published) && actions?.some(a => a === 'vm_edit_all') &&
                        <Grid item md={1.5}>
                            <Button
                                variant='outlined'
                                fullWidth
                                onClick={() => setSave(true)}
                            >
                                Save
                            </Button>
                        </Grid>
                    }
                    {version && !version.published && actions?.some(a => a === 'vm_publish') &&
                        <Grid item md={1.5}>
                            <Button
                                variant='contained'
                                fullWidth
                                onClick={() => setPublish(true)}
                            >
                                Publish
                            </Button>
                        </Grid>
                    }
                </Grid>
                {version &&
                    <Grid container direction='row' justifyContent='flex-end' alignItems='center' spacing={1}>
                        <Grid item md={2} sx={{my: 2}}>
                            <Typography>
                                <b>Valuation Name:</b> {version.name}
                            </Typography>
                        </Grid>
                        <Grid item md={3} sx={{my: 2}}>
                            <Typography>
                                <b>Valuation
                                    Period:</b> {formatDate(version.previousDate, 'dd-MMM-yyyy')} - {formatDate(version.valuationDate, 'dd-MMM-yyyy')}
                            </Typography>
                        </Grid>
                    </Grid>
                }
            </Box>
            {version && selectDate &&
                <AlertDialog
                    title='Are you sure you want to switch Dates?'
                    info='Switching dates will remove any unsaved changes'
                    open={dateChange}
                    handleClose={() => {
                        setDate(new Date(version.valuationDate))
                        setDateChange(false)
                    }}
                    handleConfirm={() => {
                        if (!!selectDate) {
                            dispatch(changeVersionsDate(selectDate))
                        }
                        setDateChange(false)
                    }}
                />
            }
            <AlertDialog
                title='Are you sure you want to switch Versions?'
                info='Switching versions will remove any unsaved changes'
                open={!!selectVersion}
                handleClose={() => setVersion(null)}
                handleConfirm={() => {
                    if (!!selectVersion) {
                        dispatch(changeVersion(selectVersion))
                    }
                    setVersion(null)
                }}
            />
            {version &&
                <AlertDialog
                    title='Confirm Save'
                    open={save}
                    handleClose={() => setSave(false)}
                    handleConfirm={() => {
                        if (save) {
                            dispatch(saveVersion(versionName))
                        }
                    }}
                    size={'sm'}
                    loading={versionSaving}
                >
                    <SaveVersionDialogContext versionName={versionName} setVersionName={setVersionName}
                                              versionsCount={versionsOnDate.length}
                                              valuationDate={version.valuationDate}
                    />
                </AlertDialog>
            }
            {version &&
                <AlertDialog
                    title='Confirm New Version'
                    open={saveAs}
                    handleClose={() => setSaveAs(false)}
                    handleConfirm={() => {
                        if (saveAs) {
                            dispatch(createVersion(versionName))
                        }
                    }}
                    size={'sm'}
                    loading={versionSaving}
                >
                    <SaveVersionDialogContext versionName={versionName} setVersionName={setVersionName}
                                              versionsCount={versionsOnDate.length}
                                              valuationDate={version.valuationDate}
                    />
                </AlertDialog>
            }
            {version &&
                <AlertDialog
                    title='Publish Version'
                    open={publish}
                    handleClose={() => setPublish(false)}
                    handleConfirm={() => {
                        if (publish) {
                            dispatch(publishVersion(versionName))
                        }
                    }}
                    size={'sm'}
                    loading={versionSaving}
                >
                    <SaveVersionDialogContext versionName={versionName} setVersionName={setVersionName}
                                              versionsCount={versionsOnDate.length}
                                              valuationDate={version.valuationDate}
                    />
                </AlertDialog>
            }
        </>
    )
}

export default memo(VersionConfigBar);

function SaveVersionDialogContext({versionName, setVersionName, versionsCount, valuationDate}: {
    versionName: string,
    setVersionName: (name: string) => void,
    versionsCount: number,
    valuationDate: Date | number
}) {

    useEffect(() => {
        if (versionName === '(New/Unsaved)') {
            let name = `Model ${formatDate(valuationDate, 'ddMM')}`;
            if (versionsCount > 1) {
                name = name + ` v${versionsCount}`
            }
            setVersionName(name)
        }
    }, [versionName, setVersionName, valuationDate, versionsCount])

    return (
        <>
            <Box sx={{p: 2}}>
                <TextField
                    label={'Version Name'}
                    value={versionName}
                    fullWidth
                    onChange={(event) => setVersionName(event.target.value)}
                />
            </Box>
        </>
    )
}
