import {useEffect} from "react";
// Store
import {useAppSelector} from "../store/store";
import {isAuthenticated} from "../store/user/userSelector";
import {CircularProgress, Container} from "@mui/material";
import {Page} from "../layouts/components";

// Login Redirect to auth app
export default function Pending({location}: {location: string}) {
    const authenticated = useAppSelector(state => isAuthenticated(state));
    const loading = useAppSelector(state => state.user.loading);
    const userCheck = useAppSelector(state => state.user.userCheck);

    useEffect(() => {
        if (!authenticated && !loading && userCheck) {
            window.location.href = `${process.env.REACT_APP_AUTH_APP}/login?redirect=${location}`;
        }
    }, [authenticated, loading, location, userCheck]);

    return (
        <Page title="...Redirecting">
            <Container
                maxWidth='sm'
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <CircularProgress/>
            </Container>
        </Page>
    );
}