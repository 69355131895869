import {Version} from "../../types/valuationModelTypes";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {versionsInitialLoad, changeVersionsDate, createVersion, saveVersion, publishVersion} from "./versionFunctions";

/**
 * Version Model for Redux Store
 */

export interface VersionState {
    versions: Array<Version>,
    version: Version | null,
    versionsOnDate: Array<Version>,
    loading: boolean,
    saving: boolean
}

const initialState: VersionState = {
    versions: [],
    version: null,
    versionsOnDate: [],
    loading: false,
    saving: false
}

export const versionSlice = createSlice({
    name: 'version',
    initialState,
    reducers: {
        changeVersion: (state, action: PayloadAction<string | number>) => {
            const version = state.versionsOnDate.find(v => v.id === action.payload)
            if (version) {
                state.version = version;
            }
        },
    },
    extraReducers(builder) {
        builder
            // Initial Versions Load
            .addCase(versionsInitialLoad.pending, (state) => {
                state.version = null;
                state.versions = [];
                state.versionsOnDate = [];
                state.loading = false;
                state.saving = false;
            })
            .addCase(versionsInitialLoad.fulfilled, (state, action) => {
                state.version = action.payload.version;
                state.versions = action.payload.versions;
                state.versionsOnDate = action.payload.versionsOnDate;
                state.loading = false;
                state.saving = false;
            })
            .addCase(versionsInitialLoad.rejected, (state) => {
                state.loading = false;
            })
            // Change Version Date
            .addCase(changeVersionsDate.fulfilled, (state, action) => {
                if (action.payload.versionsOnDate.length > 0) {
                    state.version = action.payload.version;
                    state.versionsOnDate = action.payload.versionsOnDate
                }
            })
            // SAVE NEW VERSION
            .addCase(createVersion.pending, (state) => {
                state.saving = true
            })
            .addCase(createVersion.fulfilled, (state, action) => {
                state.saving = false;
                state.versions = action.payload?.versions || [];
                state.versionsOnDate = action.payload?.versionsOnDate || [];
                state.version = action.payload?.version;
            })
            .addCase(createVersion.rejected, (state) => {
                state.saving = false;
            })

            // SAVE VERSION
            .addCase(saveVersion.pending, (state) => {
                state.saving = true;
            })
            .addCase(saveVersion.fulfilled, (state, action) => {
                state.saving = false;
                state.versions = action.payload?.versions || [];
                state.versionsOnDate = action.payload?.versionsOnDate || [];
                state.version = action.payload?.version;
            })
            .addCase(saveVersion.rejected, (state) => {
                state.saving = false;
            })

            // PUBLISH VERSION
            .addCase(publishVersion.pending, (state) => {
                state.saving = true;
            })
            .addCase(publishVersion.fulfilled, (state, action) => {
                state.saving = false;
                state.versions = action.payload?.versions || [];
                state.versionsOnDate = action.payload?.versionsOnDate || [];
                state.version = action.payload?.version;
            })
            .addCase(publishVersion.rejected, (state) => {
                state.saving = false;
            })
    }
})

export const {
    changeVersion
} = versionSlice.actions

export default versionSlice.reducer;
