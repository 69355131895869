import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
// components
import { Page } from '../layouts/components';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  // minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

// Restricted Access Page
export default function RestrictedAccess() {

  return (
    <Page title="Restricted Access">
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Restricted Access
          </Typography>

          <Typography variant="h6" sx={{ color: 'text.secondary' }}>
            You do not have access to this page.
          </Typography>

          <Box
            component="img"
            src="/static/stacked_metrics.png"
            sx={{ mx: 'auto', my: { xs: 3, sm: 6, pt: 0 }, width: 200 }}
          />

          <Button to={process.env.REACT_APP_HOME_APP || '/'} size="large" variant="contained" component={RouterLink}>
            Return Home
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
