import {InvestmentType} from "../types/ExternalDataEnums";
import {
    Adjustment,
    AdjustmentObject, Assumption,
    AxcessLoanCompare,
    Curve, FXRate,
    Valuation,
    ValuationObject,
    VMFund
} from "../types/valuationModelTypes";
import {
    AdjustmentTypeFields,
    AssumptionTypeFields,
    CurveType,
    CurveTypeFields
} from "../types/valuationModelEnums";

// Determines the curve used for the tranche
export function determineDefaultSCurve(curves: Array<Curve>, investmentType: InvestmentType): Curve | null {
    let curve: Curve | null = null;

    switch (investmentType) {

        case InvestmentType.LEVERAGED:
            curve = curves.find(c => c.curveType === CurveType.LEV_BANK) || null;
            break;

        case InvestmentType.CORPORATE:
            curve = curves.find(c => c.curveType === CurveType.CORPORATE) || null;
            break;

        case InvestmentType.FUND_FINANCE:
            curve = curves.find(c => c.curveType === CurveType.CURRENT) || null;
            break;

        case InvestmentType.PROJECT_FINANCE_INFRASTRUCTURE:
            curve = curves.find(c => c.curveType === CurveType.PROJ_FIN) || null;
            break;

        case InvestmentType.REAL_ESTATE:
            curve = curves.find(c => c.curveType === CurveType.REAL_ESTATE) || null;
            break;

        case InvestmentType.STRUCTURED:
            curve = curves.find(c => c.curveType === CurveType.CURRENT) || null;
            break;

        case InvestmentType.OTHER:
            curve = curves.find(c => c.curveType === CurveType.CURRENT) || null;
            break;

        default:
            break;
    }

    return curve
}

// Map appropriate UI label for assumption type
export function formatAssumptionLabel(type: string) {
    const assumption = AssumptionTypeFields.find(t => t.value === type);
    if (assumption) {
        return assumption.label
    } else {
        return '-'
    }
}

// Map appropriate UI label for curve overrides
export function formatCurveTypeLabel(type: string) {
    const curveType = CurveTypeFields.find(t => t.value === type);
    if (curveType) {
        return curveType.label
    } else {
        return '-'
    }
}

// Map appropriate UI label for adjustment type
export function formatAdjustmentTypeLabel(type: string) {
    const curveType = AdjustmentTypeFields.find(t => t.value === type);
    if (curveType) {
        return curveType.label
    } else {
        return '-'
    }
}

// Isolate valuation funds from tranches
export function markValuationFunds(portfolio: Array<AxcessLoanCompare>, funds: Array<VMFund>, assumptions: Array<Assumption>) {
    portfolio.forEach(p => {
        const current = p.funds.map(f => f.fund)
        const before = p.funds_before.map(f => f.fund)

        const portfolioFunds = Array.from(new Set(current.concat(before)))

        const valuationFunds: Array<string> = [];

        funds.forEach(f => {
            if (portfolioFunds.includes(f.label)) {
                valuationFunds.push(f.label)
            }
        })

        // Attach Assumptions
        const assumption = assumptions.find((a: Assumption) => a.trancheId === p.tranche_id);
        // If assumption found
        if (assumption) {
            p.assumption = assumption.type;
        }

        p.valuationFunds = valuationFunds
    })
}

// Format adjustments to indexed array
export function convertAdjustmentsToIndexedArray(adjustments: Array<Adjustment>) {
    return adjustments.reduce((index: AdjustmentObject, adjustment) => {
        const {trancheId, fund} = adjustment;

        if (!index[trancheId]) {
            index[trancheId] = {};
        }

        if (!index[trancheId][fund]) {
            index[trancheId][fund] = [];
        }

        index[trancheId][fund].push(adjustment);

        return index;
    }, {})
}

// Format adjustments to indexed array
export function convertValuationToIndexedArray(valuations: Array<Valuation>) {
    return valuations.reduce((index: ValuationObject, valuation) => {
        const {trancheId, fund} = valuation;

        if (!index[trancheId]) {
            index[trancheId] = {};
        }

        index[trancheId][fund] = valuation

        return index;
    }, {})
}

// Transform Valuations Object to single Array
export function transformValuationsObjectToList(valuationObject: ValuationObject) {
    const valuations: Array<Valuation> = []

    for (const tranche in valuationObject) {
        for (const fund in valuationObject[tranche]) {
            valuations.push(valuationObject[tranche][fund])
        }
    }

    return valuations;
}

// Transform Adjustments Object to single Array
export function transformAdjustmentsObjectToList(adjustmentsObject: AdjustmentObject) {
    const adjustments: Array<Adjustment> = []

    for (const tranche in adjustmentsObject) {
        for (const fund in adjustmentsObject[tranche]) {
            adjustments.push(...adjustmentsObject[tranche][fund])
        }
    }

    return adjustments;
}

// Extract VM BBSW from BBSW Rates
export function extract3MBbsw(apiRates: {
    id: number,
    date: Date | number,
    rates: Array<{ tenor: number, mid: number }>
}) {
    const rate = apiRates.rates.find(r => r.tenor === 3);
    if (rate) {
        return {
            id: apiRates.id,
            date: apiRates.date,
            rate: rate.mid
        };
    } else {
        throw new Error('Could not retrieve ')
    }
}

// Convert foreign currency to AUD
export function convertToAUD(fxRates: Array<FXRate>, amount: number, currency: string): number | null {
    try {
        if (currency === 'AUD') {
            return amount;
        }
        const fxRate = fxRates.find(f => f.currency === currency);
        if (fxRate) {
            return amount * (1 / fxRate.rate); // Need to take the inverse to convert to AUD
        } else {
            throw new Error("Currency " + currency + " could not be mapped against FX rates list.")
        }
    } catch (e) {
        console.log("Could not convert currency from " + currency + " to AUD.")
        console.log(e);
        return null;
    }
}