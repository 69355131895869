import {RootState} from "../../store";
import {Curve} from "../../../types/valuationModelTypes";
import {createSelector} from "@reduxjs/toolkit";
import {addValues} from "../../../utils/mathUtil";
import {GridColDef} from "@mui/x-data-grid";
import {convertValueToRating} from "../../../utils/ExternalDataUtils";


export const CurveExportColumn: Array<GridColDef> = [
    {
        field: 'ratingValue',
        headerName: 'Rating Value',
        minWidth: 50,
        hide: true
    },
    {
        field: 'rating',
        headerName: 'Rating',
        minWidth: 80,
        flex: 1
    },
    {
        field: '1',
        headerName: '1',
        minWidth: 40,
        flex: 1
    },
    {
        field: '2',
        headerName: '2',
        minWidth: 40,
        flex: 1
    },
    {
        field: '3',
        headerName: '3',
        minWidth: 40,
        flex: 1
    },
    {
        field: '4',
        headerName: '4',
        minWidth: 40,
        flex: 1
    },
    {
        field: '5',
        headerName: '5',
        minWidth: 40,
        flex: 1
    },
    {
        field: '6',
        headerName: '6',
        minWidth: 40,
        flex: 1
    },
    {
        field: '7',
        headerName: '7',
        minWidth: 40,
        flex: 1
    },
    {
        field: '8',
        headerName: '8',
        minWidth: 40,
        flex: 1
    },
    {
        field: '9',
        headerName: '9',
        minWidth: 40,
        flex: 1
    },
    {
        field: '10',
        headerName: '10',
        minWidth: 40,
        flex: 1
    }
]
type ProcessedCurve = {label: string, data: Array<{rating: string | null, [x: number]: number}>}

export const thirdPartyExportSelector = createSelector(
    (state: RootState) => state.version.version?.valuationDate,
    (state: RootState) => state.valuationModel.thirdPartyData.curves?.curves || [],
    (state : RootState) => state.valuationModel.thirdPartyData.fxRates?.rates || [],
    (state: RootState) => state.valuationModel.thirdPartyData.bbsw?.rate,
    (valuationDate, curves, fxRates, bbsw) => {
        const processedCurves: Array<ProcessedCurve> = [];
        curves.forEach(curve => {
            const rows = [];
            for (const rating in curve.curve) {
                let row = {
                    id: rating,
                    rating: convertValueToRating(parseInt(rating))
                }
                for (const index in curve.curve[rating]) {
                    row = {
                        ...row,
                        [addValues(parseInt(index), 1)]: curve.curve[rating][index]
                    }
                }
                rows.push(row);
            }
            processedCurves.push({
                label: curve.curveType,
                data: rows
            })
        })
        return {curves: processedCurves, fxRates, bbsw, valuationDate}
    }
)


export const getPortfolio = (state: RootState) => {
    return state.valuationModel.thirdPartyData.axcess?.portfolio || [];
}

export const getCurves = (state: RootState): Array<Curve> => {
    return state.valuationModel.thirdPartyData.curves?.curves || [];
}
