import React, {useMemo, useState} from "react";
import _ from "lodash";
// MUI
import {GridCellParams, GridColDef} from "@mui/x-data-grid";
import {IconButton} from "@mui/material";
import {Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";
// STORE
import {useAppDispatch, useAppSelector} from "../../../../../store/store";
import {selectAdjustmentFeesForTranche} from "../../../../../store/valuationModel/selectors/adjustmentFeesSelector";
import {Adjustment, AxcessLoanCompare, Version} from "../../../../../types/valuationModelTypes";
import { removeAdjustment } from "../../../../../store/valuationModel/valuationModelSlice";
// Components
import UpdateFeeForm from "./FeesAdjustmentsForm/UpdateFeeForm";
import AdjustmentFundFilter from "./AdjustmentFeeFilter";
import {AdjustmentType, AdjustmentTypeFields} from "../../../../../types/valuationModelEnums";
import {
    actionRow,
    AlertDialog,
    DataTable,
    defaultFormat,
    SideDrawer,
    valueCurrency
} from "../../../../../components";


const AdjustmentFeesColumns: Array<GridColDef> = [
    {
        ...defaultFormat,
        field: 'id',
        headerName: 'ID',
        hide: true,
    },
    {
        ...defaultFormat,
        field: 'transactionType',
        headerName: 'Transaction Type',
        width: 250,
        valueFormatter: (params) => AdjustmentTypeFields.find(a => a.value === params.value)?.label || params.value
    },
    {
        ...defaultFormat,
        field: 'fund',
        headerName: 'Fund',
        width: 100
    },
    {
        ...valueCurrency,
        field: 'amount',
        headerName: 'Amount',
        maxWidth: 120
    },
    {
        ...defaultFormat,
        field: 'comment',
        headerName: 'Comment',
        flex: 1
    },
]

const StandardAdjustmentsFees = ({trancheId, tranche, valuationFunds, version}: {
    trancheId: number,
    tranche: AxcessLoanCompare,
    valuationFunds: Array<string>,
    version: Version
}) => {
    const dispatch = useAppDispatch();

    const feesAdjustments = useAppSelector(state => selectAdjustmentFeesForTranche(state, trancheId));

    const [filterFund, setFilterFund] = useState<string>('All');
    const filtered = useMemo(() => (filterFund !== 'All') ? _.cloneDeep(feesAdjustments).filter(af => af.fund === filterFund) : feesAdjustments, [feesAdjustments, filterFund])

    const [remove, setRemove] = useState<string | number | null>(null)
    const [update, setUpdate] = useState<Adjustment | null>(null)

    return (
        <>
            <DataTable
                title={'Adjustments & Fees'}
                columns={[
                    ...AdjustmentFeesColumns,
                    ...(!version.published) ?
                    [{
                        ...actionRow,
                        renderCell: (params: GridCellParams) => {
                            if (params.row.transactionType === AdjustmentType.PL_ACCRUAL || params.row.transactionType === AdjustmentType.NET_REPAYMENT_PL) return <></>
                            return (
                                <>
                                    <IconButton
                                        sx={{color: 'primary.main'}}
                                        style={{padding: '0px', outline: 'none'}}
                                        onClick={() => setUpdate(params.row)}
                                        size="small"
                                    >
                                        <EditIcon/>
                                    </IconButton>
                                    <IconButton
                                        sx={{color: 'primary.main'}}
                                        style={{padding: '0px', outline: 'none'}}
                                        onClick={() => setRemove(params.row.id)}
                                        size="small"
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                </>
                            )
                        }
                    }] : []
                ]}
                data={filtered}
                loading={false}
                showExport={false}
                customFilterComponent={<AdjustmentFundFilter funds={valuationFunds} filterFund={filterFund}
                                                             setFilterFund={(fund) => setFilterFund(fund)}/>}
            />
            <SideDrawer
                title='Edit Adjustment/Fee'
                open={!!update}
                onClose={() => setUpdate(null)}
            >
                {update &&
                    <UpdateFeeForm tranche={tranche} adjustment={update} onClose={() => setUpdate(null)}/>
                }
            </SideDrawer>
            <AlertDialog
                title='Delete Adjustment/Fee'
                info='Are you sure you want to remove this Adjustment or fee?'
                open={!!remove}
                handleClose={() => setRemove(null)}
                handleConfirm={() => {
                    const adjustment = feesAdjustments.find(a => a.id === remove);
                    if (adjustment) {
                        dispatch(removeAdjustment({id: remove, trancheId: adjustment.trancheId, fund: adjustment.fund}))
                    }
                    setRemove(null)
                }}
            />
        </>
    )
}

export default StandardAdjustmentsFees;