import {RootState} from "../store";
// import {Version} from "../../types/valuationModelTypes";
import {formatDate} from "../../utils/DateUtils";
import {createSelector} from "@reduxjs/toolkit";

/**
 * Specialised Selectors for the Version Store
 */

export const getCurrentVersion = (state: RootState) => {
    return state.version.version;
}

// Retrieve Version Dates
export const getVersionDates = createSelector(
    (state: RootState) => state.version.versions,
    (versions) => (versions.map(v => formatDate(v.valuationDate, 'yyyy-MM-dd'))).sort((a, b) => a > b ? -1 : 1)
)

export const getValuationDate = (state: RootState) => {
    return state.version.version?.valuationDate
}

export const getVersionLoadingState = (state: RootState) => {
    return (state.version.loading || state.version.saving);
}

// export const getVersionsOnDate = createSelector(
//     (state: RootState) => state.version.versions,
//     (state: RootState) => state.version.version?.valuationDate,
//     (versions, date) => {
//         return versions.filter(version => {
//             if (version.valuationDate === date) {
//                 return true;
//             }
//             return false;
//         })
//     }
// )

// export const retrieveVersionDisabledDates = (state: RootState) => {
//     const versions: Version[] = state.version.versions;
//
//     const enabledDates = versions.map(v => v.valuationDate);
//
//     console.log(enabledDates)
//
//     // find min and max values
//     const minDate = (versions.length > 0) ? new Date(versions[versions.length - 1].valuationDate) : new Date();
//     const maxDate = new Date();
//
//     maxDate.setDate(maxDate.getDate() - 1)
//
//     return {
//         minDate: minDate.toDateString(),
//         maxDate: maxDate.toDateString(),
//         disabled: []
//     }
// }