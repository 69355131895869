
// Add 2 values with precession
export function addValues(vOne: number, vTwo: number, precision: number = 10): number {
    const multiplier = Math.pow(10, precision);

    return (((vOne) ? vOne : 0) * multiplier + ((vTwo) ? vTwo : 0)  * multiplier)/multiplier;
}

export function addArrayValues(values: Array<number>, precision: number = 10): number {
    let total = 0;

    values.forEach(value => total = addValues(total, value, precision));

    return total
}

export function roundToDecimal(value: number, decimals: number = 2): number {
    if (isNaN(value)) {
        return NaN
    } else {
        return Number(value.toFixed(decimals));
    }
}