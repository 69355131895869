// Imports
import {SliceCaseReducers, ValidateSliceCaseReducers} from "@reduxjs/toolkit/dist/createSlice";
// Local imports
import {ValuationModelState} from "../valuationModelSlice";
import {nanoid, PayloadAction} from "@reduxjs/toolkit";
import {TrancheMiscData} from "../../../types/valuationModelTypes";
import {runValuationReCalc} from "../valuationModelFunctions";
import { SaveStatus } from "../../../types/valuationModelEnums";

const miscReducer: ValidateSliceCaseReducers<ValuationModelState, SliceCaseReducers<ValuationModelState>> = {
    // UPDATE TRANCHE - MISC FIELDS
    updateTrancheMisc: (state, action: PayloadAction<{ current: TrancheMiscData, newValues: TrancheMiscData }>) => {
        const {
            current,
            newValues
        } = action.payload;

        const trancheId = current.trancheId;

        // Scurve update
        if (newValues.sCurve) {
            // if existing override
            if (current.sCurve.override) {
                // Find index of current override
                const overrideIndex = state.valuationModelData.sCurveOverride.findIndex(override => override.trancheId === trancheId && override.status !== SaveStatus.REMOVED);
                if (overrideIndex !== -1) {
                    const currentOverride = state.valuationModelData.sCurveOverride[overrideIndex];
                    if (!newValues.sCurve.override) {
                        // remove override
                        if (currentOverride.status === SaveStatus.NEW) {
                            state.valuationModelData.sCurveOverride = state.valuationModelData.sCurveOverride.splice(overrideIndex, 1)
                        } else {
                            state.valuationModelData.sCurveOverride[overrideIndex] = {
                                ...currentOverride,
                                status: SaveStatus.REMOVED
                            }
                        }
                    } else if (current.sCurve.curveType !== newValues.sCurve.curveType) {
                        // update override
                        state.valuationModelData.sCurveOverride[overrideIndex] = {
                            ...currentOverride,
                            curveType: newValues.sCurve.curveType,
                            status: (currentOverride.status === SaveStatus.NEW) ? SaveStatus.NEW : SaveStatus.EDITED,
                        }
                    }
                }
            } else {
                /// check for override
                if (newValues.sCurve.override) {
                    if (newValues.sCurve.curveType !== newValues.sCurve.default) {
                        state.valuationModelData.sCurveOverride.push({
                            id: nanoid(),
                            trancheId: trancheId,
                            curveType: newValues.sCurve.curveType,
                            status: SaveStatus.NEW
                        })
                    }
                }
            }
        }

        // call protected update
        if (current.callProtected !== newValues.callProtected) {
            if (!current.callProtected) {
                state.valuationModelData.callProtected.push({
                    id: nanoid(),
                    trancheId: trancheId,
                    active: true,
                    status: SaveStatus.NEW
                })
            } else if (current.callProtected && !newValues.callProtected) {
                const callProtectedIndex = state.valuationModelData.callProtected.findIndex(cp => cp.trancheId === trancheId && cp.status !== SaveStatus.REMOVED);
                if (callProtectedIndex !== -1) {
                    if (state.valuationModelData.callProtected[callProtectedIndex].status === SaveStatus.NEW) {
                        state.valuationModelData.callProtected = state.valuationModelData.callProtected.splice(callProtectedIndex, 1);
                    } else {
                        state.valuationModelData.callProtected[callProtectedIndex].status = SaveStatus.REMOVED
                    }
                }
            }
        }

        // Accrual override
        current.accrualOverride.forEach(override => {
            const newValue = newValues.accrualOverride.find(fund => fund.fund === override.fund);
            if (newValue) {
                if (newValue.active !== override.active) {
                    if (override.active) {
                        const overrideIndex = state.valuationModelData.accruedOverrides.findIndex(o => o.trancheId === trancheId && o.fund === override.fund);
                        if (overrideIndex !== -1) {
                            if (state.valuationModelData.accruedOverrides[overrideIndex].status === SaveStatus.NEW) {
                                state.valuationModelData.accruedOverrides.splice(overrideIndex, 1);
                            } else {
                                state.valuationModelData.accruedOverrides[overrideIndex].status = SaveStatus.REMOVED
                            }
                        }
                    } else {
                        state.valuationModelData.accruedOverrides.push({
                            id: nanoid(),
                            trancheId,
                            fund: override.fund,
                            active: true,
                            status: SaveStatus.NEW
                        })
                    }
                }
            }
        })

        // Recalculate Valuations
        for (const valuation in state.valuationModelData.valuations[trancheId]) {
            runValuationReCalc(state, trancheId, valuation)
        }
    }
}

export default miscReducer;