import React, {useState} from "react";
import {AxcessLoanCompare, Version} from "../../../../../types/valuationModelTypes";
// MUI
import {Chip, Divider, Grid} from "@mui/material";
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon
} from "@mui/icons-material";
import FeesAdjustmentsForm from "./FeesAdjustmentsForm";
import {useAppSelector} from "../../../../../store/store";
import {getValuationFunds} from "../../../../../store/valuationModel/selectors/generalSelectors";
import StandardAdjustmentsFees from "./StandardAdjustmentsFees";
import ScheduledAdjustmentsFees from "./ScheduledAdjustmentsFees";


/**
 * Fees and Adjustments Tab of the Tranche Wizard - handles the display of adjustments and creation section
 * @param trancheId
 * @param tranche
 * @param funds
 * @constructor
 */
const FeesAdjustments = ({trancheId, tranche}: {
    trancheId: number,
    tranche: AxcessLoanCompare
}) => {
    // Redux Retrieval
    const version = useAppSelector(state => state.version.version) as Version;
    const valuationFunds = useAppSelector(state => getValuationFunds(state));
    const actions = useAppSelector(state => state.user.user?.permissions.actions.map(a => a.permission));

    const [formOpen, handleForm] = useState<boolean>(false);


    return (
        <>
            <Grid container>
                {!version.published && actions?.some(a => a === 'vm_edit_all') &&
                    <>
                        {formOpen &&
                            <FeesAdjustmentsForm trancheId={trancheId} tranche={tranche} setOpen={handleForm}/>
                        }
                        <Grid item md={12} sx={{pb: 1}}>
                            <Divider sx={{color: 'primary.main'}}>
                                <Chip
                                    label='CREATE'
                                    onClick={() => handleForm(!formOpen)}
                                    onDelete={() => handleForm(!formOpen)}
                                    deleteIcon={(formOpen) ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                                    color='primary'
                                />
                            </Divider>
                        </Grid>
                    </>
                }
                <Grid item xs={12} sx={{minHeight: 300}}>
                    <StandardAdjustmentsFees trancheId={trancheId} tranche={tranche} valuationFunds={valuationFunds}
                                             version={version}/>
                </Grid>
                <Grid item xs={12} sx={{minHeight: 300}}>
                    <ScheduledAdjustmentsFees trancheId={trancheId} tranche={tranche} valuationFunds={valuationFunds}
                                              version={version}/>
                </Grid>
            </Grid>
        </>
    )
}


export default FeesAdjustments;