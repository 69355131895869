const MCPRatingIndex = {
    'AA+': 4,
    'AA': 5,
    'AA-': 6,
    'A+': 7,
    'A': 8,
    'A-': 9,
    'BBB+': 10,
    'BBB': 11,
    'BBB-': 12,
    'BB+': 13,
    'BB': 14,
    'BB-': 15,
    'B+': 16,
    'B': 17,
    'B-': 18,
    'CCC+': 19,
    'CCC': 20,
    'D': 21,
    'NR': null
}

export function convertRatingToValue(rating: string): number | null {
    let ratingValue;
    try {
        //@ts-ignore
        ratingValue = (MCPRatingIndex[`${rating.toUpperCase()}`])

        return ratingValue || 0
    } catch (err) {
        return 0
    }
}

export function convertValueToRating(rating: number): string | null {
    try {
        //@ts-ignore
        const ratingValue = Object.keys(MCPRatingIndex).find((key: string) => MCPRatingIndex[`${key}`] === Math.floor(rating));
        return ratingValue || null
    } catch (err) {
        return null
    }
}