// Imports
import {nanoid, PayloadAction} from "@reduxjs/toolkit";
import {SliceCaseReducers, ValidateSliceCaseReducers} from "@reduxjs/toolkit/dist/createSlice";
// Local Imports
import {ValuationModelState} from "../valuationModelSlice";
import {Adjustment, ScheduledAdjustment} from "../../../types/valuationModelTypes";
import {checkObjectChanged} from "../../../utils/generalUtils";
import {checkDateSame} from "../../../utils/DateUtils";
import {runValuationReCalc} from "../valuationModelFunctions";
import { SaveStatus } from "../../../types/valuationModelEnums";

const scheduledAdjustmentsReducer: ValidateSliceCaseReducers<ValuationModelState, SliceCaseReducers<ValuationModelState>> = {
    // Add Single ScheduledAdjustment
    addSingleScheduledAdjustment: {
        prepare(payload: {scheduledAdjustment: ScheduledAdjustment, valuationDate: Date | number}) {
            let adjustment = null;
            if (checkDateSame(payload.valuationDate, payload.scheduledAdjustment.startDate)) {
                adjustment = {
                    id: nanoid(),
                    trancheId: payload.scheduledAdjustment.trancheId,
                    valuationDate: payload.valuationDate,
                    fund: payload.scheduledAdjustment.fund,
                    transactionType: payload.scheduledAdjustment.transactionType,
                    amount: payload.scheduledAdjustment.amount,
                    isManual: true,
                    comment: payload.scheduledAdjustment.comment,
                    status: SaveStatus.NEW
                }
            }
            return {
                payload: {
                    scheduledAdjustment: {
                        ...payload.scheduledAdjustment,
                        id: nanoid(),
                        status: SaveStatus.NEW,
                        isManual: true,
                    },
                    adjustment: adjustment
                }
            }
        },
        reducer(state, action: PayloadAction<{scheduledAdjustment: ScheduledAdjustment, adjustment: Adjustment | null}>) {
            const {scheduledAdjustment, adjustment} = action.payload;
            state.valuationModelData.manualAccrual = [...state.valuationModelData.manualAccrual, scheduledAdjustment]
            if (adjustment) {
                if (!state.valuationModelData.adjustments[adjustment.trancheId]) {
                    state.valuationModelData.adjustments[adjustment.trancheId] = {}
                }
                if (!state.valuationModelData.adjustments[adjustment.trancheId][adjustment.fund]) {
                    state.valuationModelData.adjustments[adjustment.trancheId][adjustment.fund] = []
                }
                state.valuationModelData.adjustments[adjustment.trancheId][adjustment.fund] = [...state.valuationModelData.adjustments[adjustment.trancheId][adjustment.fund], adjustment]
                runValuationReCalc(state, adjustment.trancheId, adjustment.fund);
            }
        }
    },
    // Add Multiple ScheduledAdjustment
    addMultipleScheduledAdjustments: {
        prepare(payload: {adjustment: any, trancheId: number, valuationDate: number | Date}) {
            const {adjustment, trancheId, valuationDate} = payload
            const common = {
                trancheId: trancheId,
                fund: adjustment.fund,
                valuationDate: valuationDate,
                transactionType: adjustment.transactionType,
                comment: adjustment.comment,
                status: SaveStatus.NEW
            }
            const scheduledAdjustments: Array<ScheduledAdjustment> = []
            const adjustments: Array<Adjustment> = []

            payload.adjustment.funds.forEach((fund: any) => {
                if (Math.abs(fund.amount) > 0) {
                    scheduledAdjustments.push({
                        ...common,
                        fund: fund.fund,
                        id: nanoid(),
                        amount: fund.amount,
                        startDate: adjustment.startDate,
                        endDate: adjustment.endDate
                    })
                    if (checkDateSame(valuationDate, adjustment.startDate)) {
                        adjustments.push({
                            ...common,
                            fund: fund.fund,
                            id: nanoid(),
                            amount: fund.amount,
                            isManual: true
                        })
                    }
                }
            })
            return {
                payload: {
                    scheduledAdjustments: scheduledAdjustments,
                    adjustments: adjustments
                }
            }
        },
        reducer(state, action: PayloadAction<{scheduledAdjustments: Array<ScheduledAdjustment>, adjustments: Array<Adjustment>}>) {
            const {scheduledAdjustments, adjustments} = action.payload;
            const funds = state.valuationModelData.funds.map(f => f.label);
            if (scheduledAdjustments.length) {
                scheduledAdjustments.forEach(a => {
                    if (funds.includes(a.fund)) {
                        state.valuationModelData.manualAccrual = [...state.valuationModelData.manualAccrual, a]
                    }
                })
                if (adjustments.length > 0) {
                    adjustments.forEach(a => {
                        if (funds.includes(a.fund)) {
                            if (!state.valuationModelData.adjustments[a.trancheId]) {
                                state.valuationModelData.adjustments[a.trancheId] = {}
                            }
                            if (!state.valuationModelData.adjustments[a.trancheId][a.fund]) {
                                state.valuationModelData.adjustments[a.trancheId][a.fund] = []
                            }
                            state.valuationModelData.adjustments[a.trancheId][a.fund] = [...state.valuationModelData.adjustments[a.trancheId][a.fund], a]
                        }
                        runValuationReCalc(state, a.trancheId, a.fund);

                    })
                }
            }
        }
    },
    // BULK UPLOAD
    bulkScheduleAdjustments: (state, action: PayloadAction<Array<ScheduledAdjustment>>) => {
        state.valuationModelData.manualAccrual = [...state.valuationModelData.manualAccrual, ...action.payload.map(a => ({...a, status: SaveStatus.NEW}))]
    },
    // EDIT ADJUSTMENT
    editScheduledAdjustment: (state, action) => {
        const index = state.valuationModelData.manualAccrual.findIndex(a => a.id === action.payload.id && a.status !== SaveStatus.REMOVED);

        if (index !== -1) {
            let changed = false;
            if (state.valuationModelData.manualAccrual[index].status !== SaveStatus.NEW) {
                const existing = state.valuationModelData.manualAccrual[index];
                if (!checkDateSame(existing.startDate, action.payload.startDate)) changed = true;
                if (existing.endDate) {
                    if (!checkDateSame(existing.endDate, action.payload.endDate)) changed = true;
                } else if (!existing.endDate && !!action.payload.endDate) changed = true;

                if (!changed) changed = (checkObjectChanged(existing, action.payload, ['transactionType', 'amount', 'comment']));
                if (changed) {
                    action.payload.status = SaveStatus.EDITED;
                    state.valuationModelData.manualAccrual[index] = action.payload;
                }
            } else {
                state.valuationModelData.manualAccrual[index] = action.payload;
            }
        }
    },
    // REMOVE ADJUSTMENT
    removeScheduledAdjustment: (state, action: PayloadAction<number>) => {
        const index = state.valuationModelData.manualAccrual.findIndex(adjustment => adjustment.id === action.payload && adjustment.status !== SaveStatus.REMOVED);
        if (index !== -1) {
            if (state.valuationModelData.manualAccrual[index].status !== SaveStatus.NEW) {
                state.valuationModelData.manualAccrual[index].status = SaveStatus.REMOVED;
            } else {
                const manualAccrual = state.valuationModelData.manualAccrual;
                manualAccrual.splice(index, 1);
                state.valuationModelData.manualAccrual = manualAccrual;
            }
        }
    },
    // REMOVE MULTIPLE SCHEDULED ADJUSTMENTS
    removeMultipleScheduledAdjustment: (state, action: PayloadAction<Array<number | string>>) => {
        const removal = action.payload;

        removal.forEach(r => {
            const index = state.valuationModelData.manualAccrual.findIndex(adjustment => adjustment.id === r && adjustment.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                if (state.valuationModelData.manualAccrual[index].status !== SaveStatus.NEW) {
                    state.valuationModelData.manualAccrual[index].status = SaveStatus.REMOVED;
                } else {
                    const manualAccrual = state.valuationModelData.manualAccrual;
                    manualAccrual.splice(index, 1);
                    state.valuationModelData.manualAccrual = manualAccrual;
                }
            }
        })
    }
}

export default scheduledAdjustmentsReducer;