// @mui
import {Container, Grid, Typography} from '@mui/material';
// components

// Local imports
import {TransactionUpload} from "../../sections/valuationModel/BulkUpload";
import AllScheduledAdjustments from "../../sections/valuationModel/BulkUpload/AllScheduledAdjustments";
import {Page} from "../../layouts/components";

// ----------------------------------------------------------------------

export default function BulkUpload() {

    return (
        <Page title="Metrics - Valuation Model - Bulk Transfer Upload">
            <Container maxWidth={false} sx={{maxWidth: '100%', height: 0.99, paddingTop: 2}}>
                <Grid container direction='column' sx={{height: 1}}>
                    <Grid container direction='row' justifyContent='space-between'>
                        <Typography variant="h4" sx={{mb: 3, color: 'primary.main'}}>
                            Bulk Transfer Upload
                        </Typography>
                    </Grid>
                    <Grid item container sx={{width: '100%', flex: 1}} direction='row' spacing={2}>
                        <Grid item md={5}>
                            <TransactionUpload />
                        </Grid>
                        <Grid item md={7}>
                            <AllScheduledAdjustments />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
