import {Formik, FormikProps} from "formik";
import * as Yup from "yup";
// MUI
import {Box, Button, Grid, Typography} from "@mui/material";
//Local Imports
import {TrancheMiscData} from "../../../../types/valuationModelTypes";
import {InputTypes} from "../../../../types/InputTypes";
import {CurveType} from "../../../../types/valuationModelEnums";
// Components
import {FormInput} from "../../../../components";
// Store
import {useAppDispatch} from "../../../../store/store";
import {updateTrancheMisc} from "../../../../store/valuationModel/valuationModelSlice";


const EditMisc = ({data, close}: { data: TrancheMiscData, close: () => void }) => {
    const dispatch = useAppDispatch();

    return (
        <Box sx={{width: '100%'}}>
            <Formik
                initialValues={{
                    ...data,
                    curve: data.sCurve?.curveType || null
                }}
                validationSchema={Yup.object().shape({
                    curve: Yup.string().required('Curve Type is Required')
                })}
                onSubmit={(values) => {
                    const newValues = {...values};

                    if (newValues.sCurve && newValues.sCurve.override) {
                        if (newValues.curve !== newValues.sCurve.default) {
                            newValues.sCurve.curveType = newValues.curve;
                        } else {
                            newValues.sCurve.override = false;
                        }
                    }

                    dispatch(updateTrancheMisc({
                        current: data,
                        newValues: values
                    }))
                    close()
                }}
            >
                {(props: FormikProps<any>) => {
                    const {
                        handleSubmit,
                        values
                    } = props;

                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <Grid item container justifyContent='center' alignItems='center' sx={{p: 0.5}}>
                                    <Grid item xs={12} sx={{pb: 2}}>
                                        <Typography variant='h5'>
                                            S-Curve
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} container justifyContent='flex-start'>
                                        <Typography sx={{fontWeight: 'bold', color: 'primary.main', pr: 1}}>
                                            Current Curve:
                                        </Typography>
                                        <Typography>
                                            {data.sCurve?.curveType || 'N/A'}
                                        </Typography>
                                    </Grid>
                                    {(data.sCurve) &&
                                        <>
                                            <FormInput
                                                id='sCurve.override'
                                                label='Override S-Curve'
                                                fieldType={InputTypes.CHECKBOX}
                                                layout={{xs: 12, md: 12, lg: 12}}

                                            />
                                            {values.sCurve.override &&
                                                <FormInput
                                                    id='curve'
                                                    label='Curve'
                                                    fieldType={InputTypes.SELECTION}
                                                    disabled={!values.sCurve.override}
                                                    values={Object.values(CurveType).map((curve) => ({
                                                        label: curve,
                                                        value: curve
                                                    }))}
                                                />
                                            }
                                        </>
                                    }
                                    <Grid item xs={12} sx={{pb: 2}}>
                                        <Typography variant='h5'>
                                            Call Protected
                                        </Typography>
                                    </Grid>
                                    <FormInput
                                        id='callProtected'
                                        label='Call Protected'
                                        fieldType={InputTypes.CHECKBOX}
                                    />
                                    <Grid item xs={12} sx={{pb: 2}}>
                                        <Typography variant='h5'>
                                            Accrual Override
                                        </Typography>
                                    </Grid>
                                    {data.accrualOverride.map((override, o) => (
                                        <FormInput
                                            id={`accrualOverride[${o}]active`}
                                            label={override.fund}
                                            fieldType={InputTypes.CHECKBOX}
                                            key={override.fund}
                                        />
                                    ))}
                                    <Button variant='contained' type='submit'>
                                        CONFIRM CHANGES
                                    </Button>
                                </Grid>
                            </form>
                        </>
                    )
                }}
            </Formik>
        </Box>
    )
}

export default EditMisc;