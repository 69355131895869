import { RadioFieldProps } from "../../../types/InputTypes"
// MUI
import { styled } from "@mui/material/styles";
import { Grid, Theme, FormControlLabel, FormControl, FormLabel, RadioGroup, Radio, Tooltip, IconButton, FormHelperText } from "@mui/material";
import {
    HelpOutline as HelpOutlineIcon,
} from "@mui/icons-material"

// Custom styling
const GridStyle = styled(Grid)(({ theme }: { theme: Theme }) => ({
    padding: theme.spacing(1),
    width: '100%'
}))

/**
 * Checkbox Input Field
 */
export default function RadioButtonField(props: RadioFieldProps) {
    const {
        id,
        label,
        info = null,
        defaultValue = null,
        helperText,
        error,
        handleChange,
        setTouched,
        layout = { xs: 12, md: 12, lg: 12 },
        size,
        value,
        values
    } = props;

    return (
        <GridStyle
            item
            {...layout}
        >
            <FormControl>
                <FormLabel
                    id={id}
                >
                    <div>
                        {label}
                        {/* Info and Tooltips */}
                        {(info !== null) &&
                            <Tooltip title={info} placement='right'>
                                <IconButton edge="end">
                                    <HelpOutlineIcon/>
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            helperText != null &&
                            <FormHelperText error={error}>{helperText}</FormHelperText>
                        }
                    </div>
                </FormLabel>
                <RadioGroup
                    defaultValue={defaultValue}
                    name={id}
                    value={value}
                    onChange={(event) => {
                        setTouched(id)
                        handleChange((event.target as HTMLInputElement).value, id)
                    }}
                >
                    {values.map((option, o) => (
                        <FormControlLabel
                            value={option.value}
                            control={<Radio size={size}/>}
                            label={
                                <div>
                                    {option.label}
                                    {/* Info and Tooltips */}
                                    {(option.info != null) &&
                                        <Tooltip title={option.info} placement='right'>
                                            <IconButton edge="end" size='small'>
                                                <HelpOutlineIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </div>
                            }
                            key={option.value as string + o}
                        />
                    )
                    )}
                </RadioGroup>
            </FormControl>
        </GridStyle>
    )
}