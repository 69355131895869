import {forwardRef, ReactElement} from "react";
import {NumericFormat, NumericFormatProps, NumberFormatValues} from "react-number-format";
// Local Imports
import {InputTypes, PercentageFieldProps} from "../../../types/InputTypes";
import {handleFormValueChange} from "../inputUtils";
// MUI
import {styled} from "@mui/material/styles";
import {
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    TextFieldProps,
    Theme,
    Tooltip
} from "@mui/material";
import {
    HelpOutline as HelpOutlineIcon,
    Error as ErrorIcon
} from "@mui/icons-material"

// Custom styling
const GridStyle = styled(Grid)(({theme}: { theme: Theme }) => ({
    padding: theme.spacing(1),
    width: '100%'
}))

// Currency masking
// interface NumberFormatCustomProps extends NumberFormatProps {
//     ref: (instance: NumberFormat<any> | null) => void;
//     onChange: (event: { target: { name: string; value: string } }) => void;
//     name: string;
// }

// Currency Masking
interface CustomPercentageFormatterProps {
    onChange: (event: { target: { name: string; value: number | undefined } }) => void;
    name: string;
}

// Number Format Props
const defaultPercentageFieldProps = {
    thousandSeparator: true,
    fixedDecimalScale: false,
    decimalScale: 2,
    suffix: '%',
    allowNegative: false,
    isAllowed: (values: NumberFormatValues): boolean => ((0 <= parseFloat(values.value)) && (parseFloat(values.value) <= 100))
}


const CustomPercentageFormatter = forwardRef<NumericFormatProps, CustomPercentageFormatterProps>(
    function TextMaskCustom(props, ref): ReactElement {
        const {onChange, ...other} = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.floatValue
                        }
                    })
                }}
                {...defaultPercentageFieldProps}
            />
        )
    }
)


/**
 * Percentage Input Field
 * Masks number input to include decimals and '%' suffix, and limits amount to be with 0 and 100
 */
export default function PercentageField(props: PercentageFieldProps) {
    const {
        id,
        label,
        placeholder,
        info = null,
        helperText,
        error,
        disabled = false,
        handleChange,
        layout = {xs: 12, md: 12, lg: 12},
        value,
        size,
        variant,
        noUnderline,
        errorInIcon
    } = props;

    const defaultProps: TextFieldProps = {
        id,
        label,
        placeholder,
        error,
        disabled,
        value: (value !== null) ? value : 'N/A',
        onBlur: props.handleBlur,
        size,
        variant
    }

    if (!errorInIcon) {
        defaultProps.helperText = helperText;
    }

    // Info and Tooltips
    if (info) {
        defaultProps.InputProps = {
            endAdornment: (
                <InputAdornment position="end">
                    <Tooltip title={info} placement='right'>
                        <IconButton edge="end" size={size}>
                            <HelpOutlineIcon/>
                        </IconButton>
                    </Tooltip>
                </InputAdornment>
            ),
        }
    }

    // If error display in Icon
    if (errorInIcon && error) {
        defaultProps.InputProps = {
            endAdornment: (
                <InputAdornment position="end">
                    <Tooltip title={helperText} placement='right'>
                        <IconButton edge="end" sx={{pl: 0}} size={size}>
                            <ErrorIcon sx={{color: 'red'}}/>
                        </IconButton>
                    </Tooltip>
                </InputAdornment>
            )
        }
    }

    if (variant === 'standard' && noUnderline) {
        defaultProps.InputProps = {
            ...defaultProps.InputProps,
            disableUnderline: true
        }
    }

    return (
        <GridStyle
            item
            {...layout}
        >
            <TextField
                {...defaultProps}
                sx={{width: '100%'}}
                onChange={handleFormValueChange(id, InputTypes.CURRENCY, handleChange)}
                InputProps={{
                    ...(!!value) ? {inputComponent: CustomPercentageFormatter as any} : {},
                    ...defaultProps.InputProps
                }}
            />
        </GridStyle>
    )
}