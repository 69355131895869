import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    ScheduledAdjustment,
    ThirdPartyData,
    ValuationModelSet
} from "../../types/valuationModelTypes";
import {retrieveValuationModel, saveScheduledAdjustments} from "./valuationModelFunctions";
import {default as valuationModelReducers} from './reducers'

/**
 * Valuation Model for Redux Store
 */

export interface ValuationModelState {
    thirdPartyData: ThirdPartyData,
    valuationModelData: ValuationModelSet,
    fund: string | 'ALL_VM_FUNDS' | 'ALL_FUNDS',
    error: boolean,
    loading: boolean,
    calculating: boolean,
}

const initialState: ValuationModelState = {
    thirdPartyData: {
        axcess: null,
        curves: null,
        fxRates: null,
        bbsw: null
    },
    valuationModelData: {
        assumptions: [],
        valuations: [],
        adjustments: [],
        callProtected: [],
        sCurveOverride: [],
        accruedOverrides: [],
        manualAccrual: [],
        funds: []
    },
    fund: 'ALL_VM_FUNDS',
    error: false,
    loading: false,
    calculating: false
}

export const valuationModelSlice = createSlice({
    name: 'valuationModel',
    initialState,
    reducers: valuationModelReducers,
    extraReducers(builder) {
        builder
            // Initial Valuation Load
            .addCase(retrieveValuationModel.pending, (state) => {
                state.thirdPartyData = {
                    axcess: null,
                    curves: null,
                    fxRates: null,
                    bbsw: null
                };
                state.valuationModelData = {
                    assumptions: [],
                    valuations: [],
                    adjustments: [],
                    callProtected: [],
                    sCurveOverride: [],
                    accruedOverrides: [],
                    manualAccrual: [],
                    funds: []
                }
                state.loading = true;
            })
            .addCase(retrieveValuationModel.fulfilled, (state, action) => {
                state.thirdPartyData = action.payload.thirdPartyData;
                state.valuationModelData = action.payload.valuationModelData;
                state.loading = false;
            })
            .addCase(retrieveValuationModel.rejected, (state) => {
                state.error = true;
                state.loading = false;
            })
            // UPDATE SCHEDULED ADJUSTMENTS
            .addCase(saveScheduledAdjustments.pending, (state) => {
                state.loading = true;
            })
            .addCase(saveScheduledAdjustments.fulfilled, (state, action: PayloadAction<Array<ScheduledAdjustment>>) => {
                state.loading = false;
                state.valuationModelData.manualAccrual = action.payload;
            })
            .addCase(saveScheduledAdjustments.rejected, (state) => {
                state.loading = false;
            })
    }
})

export const {
    selectFund,
    addSingleAdjustment,
    addMultipleAdjustments,
    bulkScheduleAdjustments,
    editAdjustment,
    removeAdjustment,
    addSingleScheduledAdjustment,
    addMultipleScheduledAdjustments,
    editScheduledAdjustment,
    removeMultipleScheduledAdjustment,
    removeScheduledAdjustment,
    updateTrancheMisc
} = valuationModelSlice.actions;

export default valuationModelSlice.reducer;