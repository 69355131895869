// Retrieves state data for loan wizard
import {RootState} from "../../store";
import {Valuation} from "../../../types/valuationModelTypes";
import {createSelector} from "@reduxjs/toolkit";
import _ from "lodash";


export const selectValuationByTranche = createSelector(
    (state: RootState) => state.valuationModel.valuationModelData.valuations,
    (state: RootState) => _.cloneDeep(state.valuationModel.thirdPartyData.axcess?.portfolio || []),
    (state: RootState, trancheId: number) => trancheId,
    (valuations, portfolio, trancheId) => {

        const trancheData = portfolio.find(t => t.tranche_id === trancheId);
        if (trancheData) {
            const funds = trancheData.valuationFunds;
            const selectedValuations: Array<Valuation> = [];
            funds.forEach(fund => {
                const valuation = valuations[trancheId][fund];
                selectedValuations.push(valuation);
            })
            return selectedValuations
        } else {
            return [];
        }
})