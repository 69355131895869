import {RootState} from "../../store";
import {createSelector} from "@reduxjs/toolkit";

export const getValuationModelLoading = (state: RootState) => {
    return (state.valuationModel.loading || state.valuationModel.calculating)
}

export const getValuationFunds = createSelector(
    (state: RootState) => state.valuationModel.valuationModelData.funds,
    (funds) => funds.map(fund => fund.label)
)

export const getTranche = (state: RootState, trancheId: number) => {
    return state.valuationModel.thirdPartyData.axcess?.portfolio.find(t => t.tranche_id === trancheId) || null
}