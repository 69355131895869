import React, {useMemo, useState} from "react";
import _ from "lodash";
// LOCAL IMPORT
import {AdjustmentTypeFields} from "../../../types/valuationModelEnums";
import {ScheduledAdjustment} from "../../../types/valuationModelTypes";
// COMPONENTS
import {
    actionRow,
    AlertDialog,
    DataTable,
    defaultFormat,
    SideDrawer,
    valueCurrency,
    valueDate
} from "../../../components";
import UpdateScheduledFeeForm from "../Portfolio/LoanWizard/FeesAdjustments/FeesAdjustmentsForm/UpdateScheduledFeeForm";
import AdjustmentFundFilter from "../Portfolio/LoanWizard/FeesAdjustments/AdjustmentFeeFilter";
// @MUI
import {GridCellParams, GridColDef} from "@mui/x-data-grid";
import {Button, Checkbox, Grid, IconButton} from "@mui/material";
import {Delete as DeleteIcon, Edit as EditIcon, SaveAs as SaveIcon} from "@mui/icons-material";
// STORE
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {getScheduledAdjustments} from "../../../store/valuationModel/selectors/adjustmentFeesSelector";
import {removeMultipleScheduledAdjustment, removeScheduledAdjustment} from "../../../store/valuationModel/valuationModelSlice";
import {getTranche, getValuationFunds} from "../../../store/valuationModel/selectors/generalSelectors";
import {saveScheduledAdjustments} from "../../../store/valuationModel/valuationModelFunctions";


const AdjustmentFeesColumns: Array<GridColDef> = [
    {
        ...defaultFormat,
        field: 'id',
        headerName: 'ID',
        hide: true,
    },
    {
        ...defaultFormat,
        field: 'trancheId',
        headerName: 'Tranche ID',
        width: 85
    },
    {
        ...defaultFormat,
        field: 'transactionType',
        headerName: 'Transaction Type',
        flex: 1,
        valueFormatter: (params) => AdjustmentTypeFields.find(a => a.value === params.value)?.label || params.value
    },
    {
        ...defaultFormat,
        field: 'fund',
        headerName: 'Fund',
        width: 80
    },
    {
        ...valueCurrency,
        field: 'amount',
        headerName: 'Amount',
        width: 100
    },
    {
        ...valueDate,
        field: 'startDate',
        headerName: 'Start Date',
        width: 90
    },
    {
        ...valueDate,
        field: 'endDate',
        headerName: 'End Date',
        width: 90
    },
    {
        ...defaultFormat,
        field: 'comment',
        headerName: 'Comment',
        flex: 1
    },
]

const AllScheduledAdjustments = () => {
    const dispatch = useAppDispatch();

    const valuationFunds = useAppSelector(state => getValuationFunds(state));
    const scheduledAdjustments = useAppSelector(state => getScheduledAdjustments(state));
    const loading = useAppSelector(state => state.valuationModel.loading)
    const actions = useAppSelector(state => state.user.user?.permissions.actions.map(a => a.permission));


    const [filterFund, setFilterFund] = useState<string>('All');
    const filtered = useMemo(() => (filterFund !== 'All') ? _.cloneDeep(scheduledAdjustments).filter(af => af.fund === filterFund) : scheduledAdjustments, [scheduledAdjustments, filterFund])

    const [selected, setSelected] = useState<Array<string | number>>([])
    const [removeMulti, setRemoveMulti] = useState<boolean>(false);
    const [remove, setRemove] = useState<string | number | null>(null)
    const [update, setUpdate] = useState<ScheduledAdjustment | null>(null)
    const [save, setSave] = useState<boolean>(false)

    const tranche = useAppSelector(state => (update) ? getTranche(state, update.trancheId) : null)


    const handleSelectRows = (id: number | string) => {
        const selectedIndex = selected.findIndex(s => s === id);
        const updatedSelected = [...selected];

        if (selectedIndex !== -1) {
            updatedSelected.splice(selectedIndex, 1);
        } else {
            updatedSelected.push(id);
        }

        setSelected(updatedSelected);
    }

    return (
        <>
            <Grid container direction='column' justifyContent='flex-start' sx={{height: '100%'}}>
                <Grid item>
                    <Grid item container justifyContent='center' spacing={2}>
                        {actions?.some(a => a === 'vm_edit_all') &&
                            <Grid item container direction='row' justifyContent='center' sx={{pb: 2}}>

                                <Button
                                    variant="contained"
                                    startIcon={<SaveIcon/>}
                                    sx={{mx: 1}}
                                    onClick={() => setSave(true)}
                                >
                                    Save
                                </Button>

                                {selected.length > 0 &&
                                    <Button
                                        variant="contained"
                                        startIcon={<SaveIcon/>}
                                        sx={{mx: 1}}
                                        onClick={() => setRemoveMulti(true)}
                                    >
                                        Delete Selected ({selected.length})
                                    </Button>
                                }
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item sx={{flex: 1}}>
                    <DataTable
                        title={'Existing Scheduled Adjustments & Fees'}
                        columns={[
                            ...(actions?.some(a => a === 'vm_edit_all') ? [{
                                ...actionRow,
                                field: 'groupSelect',
                                headerName: '',
                                renderCell: (params: GridCellParams) => (
                                    <>
                                        <Checkbox
                                            checked={selected.includes(params.id)}
                                            onChange={() => handleSelectRows(params.id)}
                                        />
                                    </>
                                )

                            }] : []),
                            ...AdjustmentFeesColumns,
                            ...(actions?.some(a => a === 'vm_edit_all') ? [{
                                ...actionRow,
                                renderCell: (params: GridCellParams) => (
                                    <>
                                        <IconButton
                                            sx={{color: 'primary.main'}}
                                            style={{padding: '0px', outline: 'none'}}
                                            onClick={() => setUpdate(params.row)}
                                            size="small"
                                        >
                                            <EditIcon/>
                                        </IconButton>
                                        <IconButton
                                            sx={{color: 'primary.main'}}
                                            style={{padding: '0px', outline: 'none'}}
                                            onClick={() => setRemove(params.row.id)}
                                            size="small"
                                        >
                                            <DeleteIcon/>
                                        </IconButton>
                                    </>
                                )
                            }] : [])
                        ]}
                        data={filtered}
                        loading={loading}
                        showExport={false}
                        search
                        showFilter
                        customFilterComponent={<AdjustmentFundFilter funds={valuationFunds} filterFund={filterFund}
                                                                     setFilterFund={(fund) => setFilterFund(fund)}/>}
                    />
                    <SideDrawer
                        title='Edit Adjustment/Fee'
                        open={!!update}
                        onClose={() => setUpdate(null)}
                    >
                        {update && tranche &&
                            <UpdateScheduledFeeForm tranche={tranche} adjustment={update}
                                                    onClose={() => setUpdate(null)}/>
                        }
                    </SideDrawer>
                    <AlertDialog
                        title='Delete Adjustment/Fee'
                        info='Are you sure you want to remove this Scheduled Adjustment or fee?'
                        open={!!remove}
                        handleClose={() => setRemove(null)}
                        handleConfirm={() => {
                            dispatch(removeScheduledAdjustment(remove));
                            setRemove(null);
                        }}
                    />
                    <AlertDialog
                        title='Save scheduled Adjustments?'
                        info='Are you sure you want to save the current scheduled Adjustments?'
                        open={save}
                        handleClose={() => setSave(false)}
                        handleConfirm={() => {
                            if (save) {
                                dispatch(saveScheduledAdjustments())
                            }
                            setSave(false)
                        }}
                    />
                    <AlertDialog
                        title='Delete scheduled Adjustments?'
                        info={`Are you sure you want to delete ${selected.length} the current scheduled Adjustments?`}
                        open={removeMulti}
                        handleClose={() => setRemoveMulti(false)}
                        handleConfirm={() => {
                            if (removeMulti) {
                                dispatch(removeMultipleScheduledAdjustment(selected))
                            }
                            setSelected([]);
                            setRemoveMulti(false)
                        }}
                    />
                </Grid>
            </Grid>

        </>
    )
}

export default AllScheduledAdjustments;