import {configureStore} from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import notificationReducer from './notifications/notificationSlice';
import userReducer from './user/userSlice';
import valuationModelReducer from "./valuationModel/valuationModelSlice";
import versionReducer from "./version/versionSlice";

export const store = configureStore({
    reducer: {
        notifications: notificationReducer,
        user: userReducer,
        valuationModel: valuationModelReducer,
        version: versionReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector