import {useAppSelector} from "../../store/store";
import {getValuationModelLoading} from "../../store/valuationModel/selectors/generalSelectors";
import {Checkbox, Container, FormControlLabel, FormGroup, Grid, Typography} from "@mui/material";
import VersionConfigBar from "./VersionConfigBar";
import {
    AdjustmentsExportColumns,
    adjustmentsExportSelector, ScheduledAdjustmentsExportColumns
} from "../../store/valuationModel/selectors/adjustmentsExportSelector";
import {useMemo, useState} from "react";
import {AdjustmentType} from "../../types/valuationModelEnums";
import { Page } from "../../layouts/components";
import {DataTable} from "../../components";

export default function AdjustmentsExport() {
    const {adjustments, scheduledAdjustments} = useAppSelector(state => adjustmentsExportSelector(state));
    const loading = useAppSelector(state => getValuationModelLoading(state));

    const [selectManual, setManual] = useState<boolean>(true);
    const [selectRecurring, setRecurring] = useState<boolean>(false);
    const filtered = useMemo(() => {
        const filteredAdjustments = (selectManual) ? adjustments.filter(a => a.transactionType !== AdjustmentType.PL_ACCRUAL && ((a.transactionType === AdjustmentType.NET_REPAYMENT_PL && a.isManual) || (a.transactionType === AdjustmentType.ASSET_SALE && a.isManual) || (a.transactionType !== AdjustmentType.NET_REPAYMENT_PL))) : adjustments
        if (selectRecurring) {
            return filteredAdjustments;
        } else {
            const recurringAdjustments = scheduledAdjustments.filter(a => a.startDate && a.endDate);
            const recurringAdjustmentIds = recurringAdjustments.map(a => a.trancheId);
            return filteredAdjustments.filter(a => !recurringAdjustmentIds.includes(a.trancheId))
        }
    }, [selectManual, selectRecurring, adjustments, scheduledAdjustments])

    return (
        <Page title="Metrics - Valuation Model - Daily Adjustments">
            <Container maxWidth={false} sx={{maxWidth: '100%', height: 0.99, paddingTop: 2}}>
                <Grid container direction='column' sx={{height: 1}}>
                    <Grid container direction='row' justifyContent='space-between'>
                        <Typography variant="h4" sx={{mb: 3, color: 'primary.main'}}>
                            Daily Adjustments
                        </Typography>
                        <VersionConfigBar/>
                    </Grid>
                    <Grid item container sx={{width: '100%', height: '100%', flex: 1}} direction='row'>
                        <Grid item xs={12} md={12} lg={12} sx={{height: '50%', pb: 1}}>
                            <DataTable
                                title={'Adjustments'}
                                columns={AdjustmentsExportColumns}
                                data={filtered}
                                loading={loading}
                                sort={{field: 'tranche_id', sort: 'asc'}}
                                search
                                showColumns
                                showFilter

                                customFilterComponent={
                                    <FormGroup row={true}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectManual}
                                                    sx={{
                                                        color: "common.white",
                                                        '&.Mui-checked': {
                                                            color: "common.white"
                                                        }
                                                    }}
                                                />
                                            }
                                            label="Only Manual"
                                            onChange={() => setManual(!selectManual)}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectRecurring}
                                                    sx={{
                                                        color: "common.white",
                                                        '&.Mui-checked': {
                                                            color: "common.white"
                                                        }
                                                    }}
                                                />
                                            }
                                            label="Show Recurring"
                                            onChange={() => setRecurring(!selectRecurring)}
                                        />
                                    </FormGroup>
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sx={{height: '50%'}}>
                            <DataTable
                                title={'Scheduled Adjustments'}
                                columns={ScheduledAdjustmentsExportColumns}
                                data={scheduledAdjustments}
                                loading={loading}
                                sort={{field: 'tranche_id', sort: 'asc'}}
                                search
                                showColumns
                                showFilter
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}