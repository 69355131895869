import {useState} from "react";
import {AxcessLoanCompare} from "../../../../types/valuationModelTypes";
// Store
import {useAppSelector} from "../../../../store/store";
import {getTrancheMiscAttr} from "../../../../store/valuationModel/selectors/miscSelector";
// MUI
import {Box, Button, Grid, Typography} from "@mui/material";
// Components
import {InfoTable, SideDrawer} from "../../../../components";

import EditMisc from "./EditMisc";



/**
 * Shows all other misc details of tranche
 * @param trancheId
 * @param tranche
 * @constructor
 */
const TrancheMisc = ({trancheId, tranche}: { trancheId: number, tranche: AxcessLoanCompare }) => {
    const miscData = useAppSelector(state => getTrancheMiscAttr(state, trancheId));
    const published = useAppSelector(state => state.version.version?.published);
    const actions = useAppSelector(state => state.user.user?.permissions.actions.map(a => a.permission));

    const [edit, setEdit] = useState<boolean>(false)

    const sCurveData = [
        {label: 'Curve', value: miscData.sCurve?.curveType || 'N/A'},
        {label: 'Tenor', value: tranche.remaining_tenor_yrs || 'N/A'},
        {label: 'MCP Rating', value: tranche.rating_mcp || 'N/A'},
        {label: 'Fair Value', value: miscData.sCurve?.fairValue || 'N/A'},
    ];

    const otherData = [
        {label: 'Call Protected', value: (miscData.callProtected) ? 'True' : 'False'},
        {label: 'Accrual Override', value: '', sx: {borderBottom: 1}},
        ...miscData.accrualOverride.map((override: { fund: string, active: boolean }) => {
            return {label: override.fund, value: (override.active) ? 'True' : 'False'}
        })
    ]

    return (
        <>
            <Grid container spacing={2}>
                {!published && actions?.some(a => a === 'vm_edit_all') &&
                    <Grid item xs={12} container justifyContent='flex-end'>
                        <Button
                            variant='contained'
                            onClick={() => setEdit(true)}
                        >
                            EDIT
                        </Button>
                    </Grid>
                }
                <Grid item xs={6}>
                    <Typography variant='h4'>
                        Curve:
                    </Typography>
                    <Box sx={{p: 2}}>
                        <InfoTable data={sCurveData}/>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='h4'>
                        Other:
                    </Typography>
                    <Box sx={{p: 2}}>
                        <InfoTable data={otherData}/>
                    </Box>
                </Grid>
            </Grid>
            <SideDrawer
                title={'Edit Misc Details'}
                open={edit}
                onClose={() => setEdit(!edit)}
            >
                <EditMisc data={miscData} close={() => setEdit(false)}/>
            </SideDrawer>
        </>
    )
}

export default TrancheMisc;