import {GridColDef} from "@mui/x-data-grid";
import {fCurrency, fNumber} from "../../../utils/formatNumber";
import {createSelector, nanoid} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import _ from "lodash";
import {convertToAUD} from "../../../utils/valuationUtils";
import {addValues} from "../../../utils/mathUtil";
import {AdjustmentType, SaveStatus} from "../../../types/valuationModelEnums";
import {calculateITMorOTM} from "../../../utils/ValuationModelCalcs";


export const ValuationExportColumns: Array<GridColDef> = [
    {
        field: 'trancheId',
        headerName: 'ID',
        minWidth: 60,
        flex: 1
    },
    {
        field: 'fund',
        headerName: 'Fund',
        minWidth: 80,
        flex: 1
    },
    {
        field: 'borrower',
        headerName: 'Borrower',
        minWidth: 150,
        flex: 2
    },
    {
        field: 'tranche',
        headerName: 'Tranche',
        minWidth: 150,
        flex: 2
    },
    {
        field: 'tranche_type',
        headerName: 'Type',
        minWidth: 150,
        hide: true,
    },
    {
        field: 'fairValueBps',
        headerName: 'Fair Value (bps)',
        type: 'number',
        minWidth: 120,
        valueFormatter: (params) => {
            return (params.value > 0) ? Math.round(params.value) : "-"
        },
    },
    {
        field: 'base_currency',
        headerName: 'Currency',
        minWidth: 80,
        flex: 1
    },
    {
        field: 'commitment',
        headerName: 'Commitment (native)',
        type: 'number',
        minWidth: 150,
        flex: 2,
        align: 'right',
        valueFormatter: (params) => fCurrency(params.value),
    },
    {
        field: 'convertedCommitment',
        headerName: 'Commitment (AUD)',
        type: 'number',
        minWidth: 150,
        flex: 2,
        align: 'right',
        valueFormatter: (params) => fCurrency(params.value),
        valueGetter: (params) => {
            if (params.value >= 0) {
                return params.value;
            } else {
                return "ERR";
            }
        }
    },
    {
        field: 'carry',
        headerName: 'Carry (c/$)',
        minWidth: 100,
        flex: 1,
        type: 'number',
        align: 'right',
        valueFormatter: (params) => (params.value) ? fNumber(params.value) : "-",
    },
    {
        field: 'fairValue',
        headerName: 'Fair Value (c/$)',
        minWidth: 110,
        flex: 1,
        type: 'number',
        align: 'right',
        valueFormatter: (params) => (params.value) ? fNumber(params.value) : "-",
    },
    {
        field: 'cappedFairValue',
        headerName: 'Capped FV',
        minWidth: 100,
        flex: 1,
        type: 'number',
        align: 'right',
        valueFormatter: (params) => (params.value) ? fNumber(params.value) : "-",
    },
    {
        field: 'convertedItmOtm',
        headerName: 'ITM/OTM (AUD)',
        minWidth: 150,
        flex: 2,
        type: 'number',
        align: 'right',
        valueFormatter: (params) => {
            if (Math.abs(params.value) >= 0) {
                return fCurrency(params.value);
            } else {
                return "ERR";
            }
        }
    },
    {
        field: 'convertedDiscount',
        headerName: 'Discount (AUD)',
        minWidth: 150,
        flex: 2,
        type: 'number',
        align: 'right',
        valueFormatter: (params) => {
            if (Math.abs(params.value) >= 0) {
                return fCurrency(params.value);
            } else {
                return "ERR";
            }
        }
    },
    {
        field: 'carryValue',
        headerName: 'Carry Value (AUD)',
        minWidth: 150,
        type: 'number',
        align: 'right',
        flex: 2,
        valueFormatter: (params) => fCurrency(params.value),
        valueGetter: (params) => {
            if (params.row.convertedCommitment >= 0) {
                return params.row.convertedCommitment * params.row.carry / 100;
            } else {
                return "ERR";
            }
        }
    },
    {
        field: 'yieldToMaturity',
        headerName: 'YTM',
        type: 'number',
        minWidth: 100,
        flex: 1,
        valueFormatter: (params) => (params.value) ? Math.round(params.value) : "-",
    },
    {
        field: 'convertedAppliedPL',
        headerName: 'Applied P/L (AUD)',
        type: 'number',
        minWidth: 150,
        flex: 2,
        valueFormatter: (params) => {
            if (Math.abs(params.value) >= 0) {
                return fCurrency(params.value);
            } else {
                return "ERR";
            }
        }
    },
    {
        field: 'callProtected',
        headerName: 'Call Protected',
        minWidth: 100,
        flex: 1,
        align: 'right',
        valueFormatter: (params) => {
            return (params.value) ? "Yes" : "No"
        },
    }
]

export const ProfitLossSummaryColumns: Array<GridColDef> = [
    {
        field: 'fund',
        headerName: 'Fund',
        minWidth: 80,
        flex: 1
    },
    {
        field: 'totalPriorClose',
        headerName: 'Prior Closing Balance',
        type: 'number',
        minWidth: 120,
        flex: 1,
        valueFormatter: (params) => {
            return fCurrency(params.value)
        }
    },
    {
        field: 'totalFees',
        headerName: 'Fees',
        type: 'number',
        minWidth: 120,
        flex: 1,
        valueFormatter: (params) => {
            return fCurrency(params.value)
        }
    },
    {
        field: 'totalOpeningDiscount',
        headerName: 'Opening Balance',
        type: 'number',
        minWidth: 120,
        flex: 1,
        valueFormatter: (params) => {
            return fCurrency(params.value)
        }
    },
    {
        field: 'totalClosingDiscount',
        headerName: 'Closing Balance',
        type: 'number',
        minWidth: 120,
        flex: 1,
        valueFormatter: (params) => {
            return fCurrency(params.value)
        }
    },
    {
        field: 'totalAccruedPL',
        headerName: 'Daily Accruals',
        type: 'number',
        minWidth: 120,
        flex: 1,
        valueFormatter: (params) => {
            return fCurrency(params.value)
        }
    },
    {
        field: 'totalAdjustments',
        headerName: 'Adjustments to P/L',
        type: 'number',
        minWidth: 120,
        flex: 1,
        valueFormatter: (params) => {
            return fCurrency(params.value)
        }
    },
    {
        field: 'totalAppliedPL',
        headerName: 'Net Profit & Loss',
        type: 'number',
        minWidth: 120,
        flex: 1,
        valueFormatter: (params) => {
            return fCurrency(params.value)
        }
    }
]


// Selector for Valuation Export page
export const valuationExportSelector = createSelector(
    (state: RootState) => state.valuationModel.fund,
    (state: RootState) => state.valuationModel.valuationModelData.funds,
    (state: RootState) => state.valuationModel.thirdPartyData.fxRates?.rates,
    (state: RootState) => state.valuationModel.valuationModelData.callProtected,
    (state: RootState) => _.cloneDeep(state.valuationModel.thirdPartyData.axcess?.portfolio || []),
    (state: RootState) => state.valuationModel.valuationModelData.valuations,
    (fund, funds, fxRates, callProtectedAssets, portfolio, valuations) => {
        const filteredFunds: string[] = [];
        if (fund === "ALL_VM_FUNDS" || fund === "ALL_FUNDS") {
            funds.forEach(f => filteredFunds.push(f.name));
        } else {
            filteredFunds.push(fund);
        }
        const valuationsList = [];
        for (let t in valuations) {
            const trancheData = portfolio.find(p => p.tranche_id === parseInt(t));
            const callProtected = callProtectedAssets.some(c => c.trancheId === parseInt(t));
            for (let f in valuations[t]) {
                let convertedCommitment = null;
                let convertedAppliedPL = null;
                let convertedDiscount = null;
                let convertedItmOtm = null;
                if (trancheData && fxRates) {
                    convertedCommitment = convertToAUD(fxRates, valuations[t][f].commitment, trancheData.base_currency);
                    convertedAppliedPL = convertToAUD(fxRates, valuations[t][f].appliedPl, trancheData.base_currency);
                    convertedDiscount = convertToAUD(fxRates, valuations[t][f].closingDiscount, trancheData.base_currency);
                    // TODO need to change this after capped fair value change is added
                    convertedItmOtm = calculateITMorOTM(valuations[t][f].commitment, valuations[t][f].carry, valuations[t][f].cappedFairValue || valuations[t][f].fairValue)
                    convertedItmOtm = convertToAUD(fxRates, convertedItmOtm, trancheData.base_currency);
                }
                if (filteredFunds.includes(valuations[t][f].fund)) {
                    valuationsList.push({
                        ...trancheData,
                        ...valuations[t][f],
                        ...(callProtected) ? {callProtected: callProtected} : {},
                        id: t + f,
                        convertedCommitment: convertedCommitment,
                        convertedAppliedPL: convertedAppliedPL,
                        convertedDiscount: convertedDiscount,
                        convertedItmOtm: convertedItmOtm
                    })
                }
            }
        }
        return valuationsList;
    }
)

export const profitLossSummarySelector = createSelector(
    (state: RootState) => state.valuationModel.valuationModelData.funds,
    (state: RootState) => state.valuationModel.valuationModelData.valuations,
    (state: RootState) => state.valuationModel.valuationModelData.adjustments,
    (state: RootState) => state.valuationModel.thirdPartyData.fxRates?.rates,
    (state: RootState) => _.cloneDeep(state.valuationModel.thirdPartyData.axcess?.portfolio || []),
    (funds, valuations, adjustments, fxRates, tranches) => {
        let profitLossSummary: {
            [x: string]: {
                [x: string]: number,
            }
        } = {};
        funds.forEach(fund => {
            profitLossSummary[fund.label] = {
                totalAccruedPL: 0,
                totalAppliedPL: 0,
                totalAdjustments: 0,
                totalPriorClose: 0,
                totalFees: 0,
                totalOpeningDiscount: 0,
                totalClosingDiscount: 0
            }
        })
        // Calculate sum amounts for each column in PL Summary
        for (let t in valuations) {
            const tranche = tranches.find(a => a.tranche_id === parseInt(t));
            for (let f in valuations[t]) {
                if (profitLossSummary[f] && tranche && fxRates) {
                    // APPLIED PL
                    if (Math.abs(valuations[t][f].appliedPl) > 0) {
                        const appliedPL = convertToAUD(fxRates, valuations[t][f].appliedPl, tranche.base_currency);
                        profitLossSummary[f].totalAppliedPL = addValues(profitLossSummary[f].totalAppliedPL, appliedPL || 0);
                    }
                    // CLOSING DISCOUNT
                    if (Math.abs(valuations[t][f].closingDiscount) > 0) {
                        const closingDiscount = convertToAUD(fxRates, valuations[t][f].closingDiscount, tranche.base_currency);
                        profitLossSummary[f].totalClosingDiscount = addValues(profitLossSummary[f].totalClosingDiscount, closingDiscount || 0);
                    }
                    // PRIOR CLOSING DISCOUNT
                    if (Math.abs(valuations[t][f].openingDiscount) > 0) {
                        const priorCloseDiscount = convertToAUD(fxRates, valuations[t][f].openingDiscount, tranche.base_currency);
                        profitLossSummary[f].totalPriorClose = addValues(profitLossSummary[f].totalPriorClose, priorCloseDiscount || 0);
                    }
                    // FEES & ADJUSTED OPENING BALANCE
                    let totalFees = 0;
                    if (adjustments[t] && adjustments[t][f]) {
                        totalFees = adjustments[t][f].reduce((total, fee) => {
                            if (fee.status !== SaveStatus.REMOVED) {
                                if (fee.transactionType === AdjustmentType.ASSET_PURCHASE || fee.transactionType === AdjustmentType.ASSET_SALE || fee.transactionType === AdjustmentType.NEW_ASSET) {
                                    total = addValues(total, fee.amount);
                                } else if (fee.transactionType === AdjustmentType.FEE_RECEIVED) {
                                    total = addValues(total, -Math.abs(fee.amount));
                                }
                            }
                            return total;
                        }, 0)
                        const convertedFees = convertToAUD(fxRates, totalFees, tranche.base_currency);
                        profitLossSummary[f].totalFees = addValues(profitLossSummary[f].totalFees, convertedFees || 0);
                    }
                    const adjustedOpeningDiscount = addValues(valuations[t][f].openingDiscount, totalFees);
                    const convertedAdjOpeningDiscount = convertToAUD(fxRates, adjustedOpeningDiscount, tranche.base_currency);
                    profitLossSummary[f].totalOpeningDiscount = addValues(profitLossSummary[f].totalOpeningDiscount, convertedAdjOpeningDiscount || 0);
                }
            }
        }
        // Extract total Accrued PL & Net PL from valuations
        for (let t in adjustments) {
            const tranche = tranches.find(a => a.tranche_id === parseInt(t));
            for (let f in adjustments[t]) {
                if (profitLossSummary[f] && tranche && fxRates) {
                    const accruedPLAdj = adjustments[t][f].find(a => a.transactionType === AdjustmentType.PL_ACCRUAL && a.status !== SaveStatus.REMOVED);
                    const netRepaymentBasedPLAdj = adjustments[t][f].find(a => a.transactionType === AdjustmentType.NET_REPAYMENT_PL && a.status !== SaveStatus.REMOVED);
                    const profitLossOverrideAdj = adjustments[t][f].find(a => a.transactionType === AdjustmentType.APPLIED_PL_OVERRIDE && a.status !== SaveStatus.REMOVED);
                    if (accruedPLAdj && Math.abs(accruedPLAdj.amount) > 0) {
                        const accruedPL = convertToAUD(fxRates, accruedPLAdj.amount, tranche.base_currency);
                        profitLossSummary[f].totalAccruedPL = addValues(profitLossSummary[f].totalAccruedPL, accruedPL || 0);
                    }
                    if (profitLossOverrideAdj && Math.abs(profitLossOverrideAdj.amount) > 0) {
                        const profitLossOverride = convertToAUD(fxRates, profitLossOverrideAdj.amount, tranche.base_currency);
                        profitLossSummary[f].totalAdjustments = addValues(profitLossSummary[f].totalAdjustments, profitLossOverride || 0);
                    } else if (netRepaymentBasedPLAdj && Math.abs(netRepaymentBasedPLAdj.amount) > 0) {
                        const netRepaymentBasedPL = convertToAUD(fxRates, netRepaymentBasedPLAdj.amount, tranche.base_currency);
                        profitLossSummary[f].totalAdjustments = addValues(profitLossSummary[f].totalAdjustments, netRepaymentBasedPL || 0);
                    }
                }
            }
        }
        // Breaks object into array of object
        return Object.entries(profitLossSummary).map((e) => (
            {
                ...{fund: e[0]},
                ...e[1],
                id: nanoid()
            }
        ))
    }
)