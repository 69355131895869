import React, {useState} from "react";
import {AxcessLoanCompare} from "../../../../../../types/valuationModelTypes";
import {Grid, ToggleButton, ToggleButtonGroup} from "@mui/material";
import SingleFeeForm from "./SingleFeeForm";
import TrancheFeeForm from "./TrancheFeeForm";
import {AdjustmentType} from "../../../../../../types/valuationModelEnums";


const FeesAdjustmentsForm = ({trancheId, tranche,  setOpen}: { trancheId: number, tranche: AxcessLoanCompare, setOpen: (open: boolean) => void }) => {
    const [formType, setFormType] = useState<'single' | 'tranche'>('tranche');

    const handleFormChange = (event: React.MouseEvent<HTMLElement>, type: 'single' | 'tranche') => {
        if (type && formType !== type) {
            setFormType(type)
        }
    }

    return (
        <>
            <Grid item container md={12} alignItems='center' justifyContent='center' sx={{pb: 0}}>
                <ToggleButtonGroup
                    value={formType}
                    exclusive
                    onChange={handleFormChange}
                    color='primary'
                >
                    <ToggleButton value='single' sx={{py: 0.5, width: 100}}>SINGLE</ToggleButton>
                    <ToggleButton value='tranche' sx={{py: 0.5, width: 100}}>TRANCHE</ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <Grid item container md={12}>

                {(formType === 'single') &&
                    <SingleFeeForm trancheId={trancheId} tranche={tranche} setOpen={setOpen} />
                }
                {(formType === 'tranche') &&
                    <TrancheFeeForm trancheId={trancheId} tranche={tranche} setOpen={setOpen}/>
                }
            </Grid>
        </>
    )
}

export function determineAdjustmentDiscountPremium(type: AdjustmentType | null | '', value: number): string {
    switch (type) {
        case AdjustmentType.PL_ACCRUAL:
        case AdjustmentType.NET_REPAYMENT_PL:
        case AdjustmentType.APPLIED_PL_OVERRIDE:
            return (value > 0) ? 'Profit' : 'Loss';

        case AdjustmentType.NEW_ASSET:
            return (value > 0) ? 'Premium' : 'Discount';

        case AdjustmentType.ASSET_PURCHASE:
        case AdjustmentType.FEE_RECEIVED:
            return 'Fee Received'

        case AdjustmentType.ASSET_SALE:
            return 'Fee Paid'

        default:
            return ''
    }
}

export default FeesAdjustmentsForm;