import {useEffect} from "react";
// Local Imports
import {DateFieldProps} from "../../../types/InputTypes";
import {checkDateBefore, checkValidDate} from "../../../utils/DateUtils";
// MUI
import {styled} from "@mui/material/styles";
import {
    Grid,
    IconButton,
    InputAdornment,
    Theme,
    Tooltip
} from "@mui/material";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {
    HelpOutline as HelpOutlineIcon,
    Error as ErrorIcon
} from "@mui/icons-material"

// Custom styling
const GridStyle = styled(Grid)(({theme}: { theme: Theme }) => ({
    padding: theme.spacing(1),
    width: '100%'
}))

/**
 * Date Input Field
 */
export default function DateField(props: DateFieldProps) {
    const {
        id,
        label,
        info = null,
        helperText,
        error,
        touched,
        disabled = false,
        handleBlur,
        handleChange,
        setTouched,
        setError,
        layout = {xs: 12, md: 12, lg: 12},
        size,
        variant,
        noUnderline,
        errorInIcon,
        value,
        minDate = '1900-01-01',
        maxDate = '9001-01-02',
        nullable = false
    } = props;

    const handleValueChange = (date: Date | string | null) => {
        if (!touched) setTouched(id);
        if (date !== 'Invalid Date.') {
            handleChange(date, id);
        }
    }

    useEffect(() => {
        // checks for validation of Date input
        let err: boolean = false;
        let errInfo: undefined | string = undefined

        if (touched) {
            if (value) {
                const date = new Date(value)
                if ((!checkValidDate(date) && !nullable)) {
                    err = true;
                    errInfo = 'Invalid Date.'
                } else {
                    // Checks against minimum date
                    if (checkDateBefore(date as unknown as string, minDate)) {
                        err = true;
                        errInfo = 'Date exceeds minimum date.';
                        // Check against maximum date
                    } else if (checkDateBefore(maxDate, value as unknown as string)) {
                        err = true;
                        errInfo = 'Date exceeds maximum date.';
                    } else {
                        err = false;
                        errInfo = undefined;
                    }
                }
            }
            if (!error && err) {
                setError(id, errInfo);
            } else if (error && err && helperText !== errInfo) {
                setError(id, errInfo);
            }
        }
    }, [touched, value, nullable, minDate, maxDate, error, setError, id, helperText])

    return (
        <GridStyle
            item
            {...layout}
        >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                    label={label}
                    value={determineInitialValue(value)}
                    minDate={new Date(minDate)}
                    maxDate={new Date(maxDate)}
                    onChange={(newValue: Date | any) => {
                        handleValueChange(newValue);
                    }}
                    format={'dd/MM/yyyy'}
                    disabled={disabled}
                    slotProps={{
                        textField: {
                            sx: {width: '100%'},
                            helperText: (!errorInIcon) ? helperText : '',
                            error,
                            disabled,
                            size,
                            variant,
                            onBlur: handleBlur,
                            InputProps: {
                                endAdornment: (
                                    <>
                                        {/* Info and tooltips */}
                                        {!errorInIcon ?
                                            info &&
                                            <InputAdornment position="end">
                                                <Tooltip title={info} placement='right'>
                                                    <IconButton edge="end" size={size}>
                                                        <HelpOutlineIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                            :
                                            errorInIcon && error &&
                                            <InputAdornment position="end">
                                                <Tooltip title={helperText} placement='right'>
                                                    <IconButton edge="end" sx={{pl: 0}} size={size}>
                                                        <ErrorIcon sx={{color: 'red'}}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        }
                                    </>
                                ),
                                ...(variant === 'standard' && noUnderline ? {disableUnderline: true} : {})
                            }
                        }
                    }}

                    // renderInput={(params: any) => {
                    //     return (
                    //         <TextField
                    //             {...params}
                    //             sx={{ width: '100%' }}
                    //             helperText={(!errorInIcon) ? helperText : ''}
                    //             error={error}
                    //             disabled={disabled}
                    //             size={size}
                    //             variant={variant}
                    //             onBlur={handleBlur}
                    //             InputProps={{
                    //                 endAdornment: (
                    //                     <>
                    //                         {params.InputProps?.endAdornment}
                    //                         {/* Info and tooltips */}
                    //                         {!errorInIcon ?
                    //                             info &&
                    //                             <InputAdornment position="end">
                    //                                 <Tooltip title={info} placement='right'>
                    //                                     <IconButton edge="end" size={size}>
                    //                                         <HelpOutlineIcon/>
                    //                                     </IconButton>
                    //                                 </Tooltip>
                    //                             </InputAdornment>
                    //                             :
                    //                             errorInIcon && error &&
                    //                             <InputAdornment position="end">
                    //                                 <Tooltip title={helperText} placement='right'>
                    //                                     <IconButton edge="end"  sx={{pl: 0}} size={size}>
                    //                                         <ErrorIcon sx={{ color: 'red' }} />
                    //                                     </IconButton>
                    //                                 </Tooltip>
                    //                             </InputAdornment>
                    //                         }
                    //
                    //                     </>
                    //
                    //                 ),
                    //                 ...(variant === 'standard' && noUnderline ? { disableUnderline: true } : {})
                    //             }}
                    //         />
                    //     );
                    // }}
                />
            </LocalizationProvider>
        </GridStyle>
    );
}

// Function ensures that DateField receives an acceptable value of null, number or Date object if the string date is
// Invalid the date will be treated as Invalid and handles as a null date.
function determineInitialValue(value: string | number | Date | null) {
    if (typeof(value) === 'string') {
        if (value === '') return null;
        else {
            return new Date(value)
        }
    } else {
        return value;
    }
}