import {SliceCaseReducers, ValidateSliceCaseReducers} from "@reduxjs/toolkit/dist/createSlice";
import adjustmentsReducer from "./adjustmentsReducer";
import {ValuationModelState} from "../valuationModelSlice";
import scheduledAdjustmentsReducer from "./scheduledAdjustmentsReducer";
import miscReducer from "./miscReducer";
import {PayloadAction} from "@reduxjs/toolkit";


const reducers: ValidateSliceCaseReducers<ValuationModelState, SliceCaseReducers<ValuationModelState>> = {
    ...adjustmentsReducer,
    ...scheduledAdjustmentsReducer,
    ...miscReducer,
    selectFund: (state, action: PayloadAction<string>) => {
        state.fund = action.payload;
    },
}

export default reducers;